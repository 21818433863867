require('./bootstrap');

$('[data-toggle="tooltip"]').tooltip()









